import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.tableData,
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        prop: "text",
        label: "Please Wait...",
        width: "180"
      })
    ]),
    _: 1
  }, 8, ["data"])), [
    [_directive_loading, _ctx.loading]
  ])
}